.nav {
    z-index: 1000;
    background-color: rgb(164, 145, 170);
    padding: 1vmax;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgba(43, 43, 43, 0.137);
}

.navflex {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.navlinks {
    color: rgb(59, 59, 59);
    text-decoration: none;
    margin: 1vmax 1.2vmax;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    font-size: 1.1vmax;
}

.navlinks:hover {
    text-decoration: none;
    color: orangered;
}

.logo {
    /* background-color: orangered; */
    /* width: 58px; */
    height: 100px;
    border-radius: 20px;
    /* overflow: hidden; */
    cursor: pointer;
    /* position: relative; */
    /* right: 2vmax; */
}

#logoImage {
    transition: all 0.2s;
    height: 100%;
    transform: translateX(0);
}

#logoImage:hover {
    transform: translateX(58px);
}

.searchbox {
    position: relative;
}

.searchbox>input {
    border: none;
    border-bottom: 1px solid rgba(128, 128, 128, 0.76);
    width: 15vmax;
    font-size: 1.1vmax;
    outline: none;
    color: rgb(61, 61, 61);
    font-weight: 100;
}

.searchbox>svg {
    fill: rgb(61, 61, 61);
}


.icon_image {
    fill: #fff;
    text-decoration: none;
}

.icon_image::after {
    content: "";
}

.icon_image::before {
    content: "";
}