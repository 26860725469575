.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 25px;
  right: 30px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.whatsapp-icon {
  margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp_float {
      width: 40px;
      height: 40px;
      bottom: 20px;
      right: 10px;
      font-size: 22px;
  }
}




.facebook_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 110px;
  right: 30px;
  /* bottom: 40px;
  right: 200px;   */
  background-color: #f6f6f8; 
  /* color: #FFF; */
  /* border-radius: 50px; */
  text-align: center;
  font-size: 20px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.facebook-icon {
  margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .facebook_float {
      width: 40px;
      height: 40px;
      bottom: 80px;
      right: 10px;
      font-size: 22px;
  }
}



.phone_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 200px;
  right: 30px;
  background-color: #ffffff; 
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 40px;
  box-shadow: 2px 2px 3px rgb(0, 0, 0); 
  z-index: 100;
}

.phone-icon {
  margin-top: 6px;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .phone_float {
      width: 40px;
      height: 40px;
      bottom: 130px;
      right: 10px;
      font-size: 22px;
  }
}


