.starProduct {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-content: center;
    justify-items: center;
    margin: 1vmax auto;
    gap: 1rem;
}

@media (max-width: 750px) {
    .starProduct {
        grid-template-columns: 1fr;
    }
}

/* .starProduct>div:first-child {
    height: 100%;
}
.starProduct>div:first-child img {
    width: 100%;
    height: 100%;
} */

/* .starProduct>div:last-child{
    display: flex;
    flex-wrap: wrap;
    width: 46%;
} */

/* .starProduct>div:last-child img {
    width: 100%;
    height: 36vh;
} */

.starProduct img {
    height: 150px;
    width: 250px;
    transition: all 0.5s;
}

.starProduct img:hover {
    box-shadow: 6px 6px 15px rgba(53, 53, 53, 0.363);
}